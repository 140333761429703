import {IAppliedFilter} from "@/app/models/auctor";

export const evaluateExpressionWithFilters = (expression: string, appliedFilters: IAppliedFilter[]) => {
    try {
        expression = prepareExpression(expression)

        expression = populateExpressionWithAppliedFilters(expression, appliedFilters)

        const expressionFunction = new Function('appliedFilters', expression)

        return expressionFunction(appliedFilters)
    } catch (error) {
        console.info(`Error evaluating expression`, expression, error)
        return false
    }
}

export const prepareEvaluableExpression = (
    expression: string,
    appliedFilters: IAppliedFilter[],
    context?: any
): string | null => {
    try {
        let preparedExpression = prepareExpression(expression, context);
        preparedExpression = populateExpressionWithAppliedFilters(preparedExpression, appliedFilters);
        return preparedExpression;
    } catch (error) {
        console.error(`Error preparing expression: ${expression}`, error);
        return null;
    }
};

export const executeExpression = (
    preparedExpression: string | null,
    data: any,
    appliedFilters: IAppliedFilter[]
): boolean => {
    if (!preparedExpression) return false; // Prevent execution if preparation failed

    try {
        const expressionFunction = new Function('data', 'appliedFilters', preparedExpression);
        return expressionFunction(data, appliedFilters);
    } catch (error) {
        console.error(`Error evaluating the following filter expression: ${preparedExpression}`, error);
        return false;
    }
};

export const evaluateExpressionWithFiltersAndRowData = (
    expression: string,
    data: any,
    appliedFilters: IAppliedFilter[],
    context?: any
): any => {
    const preparedExpression = prepareEvaluableExpression(expression, appliedFilters, context);
    return executeExpression(preparedExpression, data, appliedFilters);
};

export const prepareExpression = (expression: string, context?: string) => {
    if (!expression.includes('return')) {
        expression = `return ${expression}`
    }

    if (!context) {
        return expression
    }

    return `${context}
        ${expression}`
}

export const populateExpressionWithAppliedFilters = (expression: string, appliedFilters: IAppliedFilter[]) => {
    let initVariables = ``
    appliedFilters.forEach((filter: any) => {
        initVariables += `
            let ${filter.variableName} = null
        `
    })

    appliedFilters.forEach((filter: any) => {
        initVariables += `
            if (!${filter.variableName}) {
                ${filter.variableName} = appliedFilters.find(aFilter => aFilter.filterId === '${filter.filterId}')
            }`
    })

    return `${initVariables}\n ${expression}`
}
